// src/App.js
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home';
import Meta from './meta';
import Thanks from './thanks';
import GetStarted from './get-started';
import Subscribe from './subscribe';
import { initGA, logPageView } from './utils/ga4';

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [pathname, hash]);

  return null;
};

const App = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'G-W28QLQJG80' // Replace with your GTM ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    initGA('G-W28QLQJG80'); // Replace with your GA4 Measurement ID
  }, []);

  return (
      <Router>
        <AppContent />
      </Router>
  );
};

const AppContent = () => {
  usePageViews();
  return (
      <>
        <Meta />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/subscribe" element={<Subscribe />} />
        </Routes>
      </>
  );
};

const usePageViews = () => {
  const location = useLocation();
  useEffect(() => {
    logPageView();
  }, [location]);
};

export default App;


